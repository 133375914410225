import * as React from 'react';
import { graphql } from 'gatsby';
import * as XLSX from 'xlsx';
import axios from 'axios';
import './_tableStyles.scss';

export const TableCards = ({ slice }) => {
  const { items } = slice;

  return (
    <section className={`content-section card-table`}>
      <div className="card-table__container">
        {items.map((card, index) => (
          <div key={index} className="card-table__card">
            <a href={card.card_link.url}>
              <img src={card.card_image.url} alt={card.card_image.alt} />
              <h3>{card.card_title.text}</h3>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ForumPageDataBodyTableCards on PrismicForumPageDataBodyTableCards {
    items {
      card_image {
        alt
        url
      }
      card_link {
        url
      }
      card_title {
        text
      }
      order
    }
  }
`;
