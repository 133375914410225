import * as React from 'react';
import { graphql } from 'gatsby';
// the styling for the table component
// import './styles.css';

export const Table = ({ slice }) => {
  const createTable = () => {
    return { __html: `${slice.primary.table.text}` };
  };
  return (
    <section className={`content-section`}>
      <div className="preformatted" dangerouslySetInnerHTML={createTable()} />
    </section>
  );
};

export const query = graphql`
  fragment NewsDataBodyTable on PrismicNewsDataBodyTable {
    id
    primary {
      table {
        text
      }
    }
  }
  fragment ForumPageDataBodyTable on PrismicForumPageDataBodyTable {
    id
    primary {
      table {
        text
      }
    }
  }
  fragment PageDataBodyTable on PrismicPageDataBodyTable {
    id
    primary {
      table {
        text
      }
    }
  }
`;
// fragment HomepageDataBodyQuote on PrismicHomepageDataBodyQuote {
//   primary {
//     quote {
//       raw
//     }
//   }
// }
