import * as React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { linkResolver } from '../utils/LinkResolver';
import { Layout } from '../components/Layout';
import { Container } from 'react-bootstrap';
import { Seo } from '../components/Seo';
import CollectionPageCard from '../components/Collection_Page/Card-component';

const CollectionPage = ({ data }) => {
  if (!data) return null;

  const doc = data.prismicForumCollectionPage.data;
  const pageContent = data.prismicForumCollectionPage;
  const { lang, type, url } = pageContent;
  const page = pageContent.data || {};
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const topMenu = data.prismicForumMenuLinks || {};

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc} isForum>
      <Seo title={page.title.text} lang={lang} />
      <Container>
        <h1 className="collection-page-title">{doc.title.text}</h1>
        {doc.description.text && <p className="m-3 mb-5 text-center">{doc.description.text}</p>}

        <div className="cards">
          {doc.links &&
            doc.links.map((card, key) => {
              return (
                <CollectionPageCard
                  key={key}
                  cardData={card}
                  url={card.link.url}
                  title={card.link_title.text}
                  bg_image={card.background_image}
                />
              );
            })}
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query forumCollectionPageQuery($id: String, $lang: String) {
    prismicForumCollectionPage(id: { eq: $id }, lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      type
      url
      lang
      _previewable
      data {
        title {
          text
        }
        description {
          text
          raw
        }
        links {
          background_image {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, width: 321, height: 256)
          }
          link {
            url
          }
          link_title {
            text
          }
        }
      }
    }
    prismicForumMenuLinks(lang: { eq: $lang }) {
      ...ForumMenuLinksFragment
    }
  }
`;

export default withPrismicPreview(CollectionPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);

// data: { title: { text: { eq: "About Enter Network" } } }
