import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { Navbar, Container, Offcanvas, Nav, NavbarBrand } from 'react-bootstrap';
import { LanguageSwitcher } from '../LanguageSwitcher';
import NavLink from '../Helpers-components/NavBar-components/NavLink-component';
import LogoVideo from '../../images/enter.mp4';
import forumLogo from '../../images/forum_logo.gif';

export function Header({ isForum, isHomepage, topMenu, activeDocMeta, isSignedIn }) {
  const renderedMenuLinks =
    topMenu?.menu_links || {}
      ? topMenu.menu_links?.map((menuLink, index) => {
          if (index !== 5)
            return (
              <NavLink
                key={`top-nav-${index}`}
                loginBtnClass=""
                navLinkClass="nav-link"
                id={menuLink.menu_link.id}
                to={menuLink.menu_link.url}
              >
                {RichText.asText(menuLink.link_title.raw).toUpperCase()}
              </NavLink>
            );
          if (index === 5 && !isSignedIn)
            return (
              <NavLink
                key={`top-nav-${index}`}
                loginBtnClass="nav-link_login"
                navLinkClass=""
                id={menuLink.menu_link.id}
                to={menuLink.menu_link.url}
              >
                {RichText.asText(menuLink.link_title.raw).toUpperCase()}
              </NavLink>
            );
          if (index === 5 && isSignedIn)
            return (
              <NavLink
                key={`top-nav-${index}`}
                loginBtnClass="nav-link_login"
                navLinkClass=""
                id={menuLink.menu_link.id}
                to={menuLink.menu_link.url}
              >
                {RichText.asText(menuLink.link_title.raw).toUpperCase()}
              </NavLink>
            );
        })
      : null;
  const renderedForumMenuLinks =
    topMenu?.menu_links || {}
      ? topMenu.forum_menu_links?.map((menuLink, index) => {
          return (
            <NavLink
              key={`top-nav-${index}`}
              loginBtnClass=""
              navLinkClass="nav-link"
              id={menuLink.forum_menu_link.id}
              to={menuLink.forum_menu_link.url}
            >
              {RichText.asText(menuLink.link_title.raw).toUpperCase()}
            </NavLink>
          );
        })
      : null;

  const homepageClass = isHomepage ? 'homepage-header' : '';

  return (
    <Navbar className={`site-header ${homepageClass}`} expand="lg" variant="dark">
      <Container fluid className="justify-content-between">
        {!isForum && (
          <Link to={topMenu.menu_links?.[0].menu_link.url}>
            <NavbarBrand>
              <video width="80" height="80" autoPlay loop muted playsInline>
                <source src={LogoVideo} type="video/mp4" />
                <source src={LogoVideo} type="video/ogg" />
              </video>
              {/* <img src={img} alt="" /> */}
              <h1 className="mx-2">
                EDUCATORS
                <br />
                PROFESSIONAL
                <br />
                DEVELOPMENT
              </h1>
            </NavbarBrand>
          </Link>
        )}
        {isForum && (
          <Link to={topMenu.forum_menu_links?.[0].forum_menu_link.url}>
            <NavbarBrand>
              <img width="80" src={forumLogo} alt="forum logo" />
            </NavbarBrand>
          </Link>
        )}
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={`main-nav`}>{isForum ? renderedForumMenuLinks : renderedMenuLinks}</Nav>
          {!isForum && <LanguageSwitcher activeDocMeta={activeDocMeta} />}
        </Navbar.Collapse>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          className="d-flex d-lg-none"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-center flex-grow-1">
              {isForum ? renderedForumMenuLinks : renderedMenuLinks}
            </Nav>
            {!isForum && (
              <div>
                <LanguageSwitcher activeDocMeta={activeDocMeta} />
              </div>
            )}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export const query = graphql`
  fragment MenuLinkFragment on PrismicMenuLink {
    _previewable
    type
    lang
    data {
      menu_links {
        link_title {
          raw
          text
        }
        menu_link {
          id
          url
        }
      }
    }
  }
  fragment ForumMenuLinksFragment on PrismicForumMenuLinks {
    _previewable
    type
    lang
    data {
      forum_menu_links {
        link_title {
          raw
          text
        }
        forum_menu_link {
          id
          url
        }
      }
    }
  }
`;
