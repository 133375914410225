import * as React from 'react';
import { linkResolver } from '../../utils/LinkResolver';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Row, Container, Col } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { defaultLanguage } from '../../../prismic-configuration';
import forumLogo from '../../images/forum_logo.gif';

import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

export const Footer = ({ activeDocMeta, isForum }) => {
  const queryData = useStaticQuery(graphql`
    query prismicFooter {
      allPrismicFooterVariables {
        nodes {
          lang
          data {
            all_rights_reserved
            copyright___2020__
            developed_by
            development
            disclaimer
            educators
            email
            enter
            our_contacts_
            privacy_policy
            professional
          }
        }
      }
      allPrismicForumFooterVariables {
        nodes {
          lang
          data {
            all_rights_reserved
            copyright___2020__
            disclaimer
            email
            enter
            our_contacts_
            privacy_policy
          }
        }
      }
    }
  `);

  const { lang } = activeDocMeta;
  const footerData = queryData.allPrismicFooterVariables.nodes.filter(
    langVer => langVer.lang === lang,
  )?.[0]?.data;

  const forumFooterData = queryData.allPrismicForumFooterVariables.nodes.filter(
    langVer => langVer.lang === lang,
  )?.[0]?.data;
  const footerDocData = isForum ? forumFooterData : footerData;
  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;
  // ${!!isForum ? `euraceconnect/forum-privacy-policy` : 'privacy'}
  //forum-privacy-policy
  return (
    <footer className="footer">
      <Container>
        <Row className="footer-row">
          <Col className="footer-flex" md={4}>
            <span className="back-to-top">
              <span
                onClick={() => {
                  document.body.scrollTop = 0;
                  document.documentElement.scrollTop = 0;
                }}
              >
                <i className="fa fa-angle-up fa" aria-hidden="true" />
              </span>
            </span>
            <Link to={`${localLinkResolver}/${!!isForum ? `euraceconnect` : ''}`}>
              {isForum ? (
                <div className="footer-logo">
                  <img width="80" src={forumLogo} alt="forum logo" />
                </div>
              ) : (
                <div className="footer-logo">
                  <StaticImage
                    src="../../images/static-logo.png"
                    alt="logo"
                    width={80}
                    height={80}
                  />
                  <p>
                    {footerDocData.educators}
                    <br />
                    {footerDocData.professional}
                    <br />
                    {footerDocData.development}
                  </p>
                </div>
              )}
            </Link>
            <div>
              <hr />
              <p className="footer__text">
                {footerDocData.all_rights_reserved} {'  '}
                <Link to={`${localLinkResolver}/`} rel="noopener noreferrer">
                  {footerDocData.enter}
                </Link>
                <br />
                <span>
                  {footerDocData.copyright___2020__} {new Date().getFullYear()}
                </span>
              </p>
            </div>
          </Col>
          <Col className="footer-flex" md={4}></Col>

          <Col className="footer-flex" md={4}>
            <div className="footer__text">
              <div> {footerDocData.our_contacts_}</div>
              <a rel="noopener noreferrer" href="/" target="_blank">
                <i className="fa fa-envelope" aria-hidden="true"></i>
                {footerDocData.email}
              </a>
              <hr />
            </div>
            <div className="footer__text">
              <span>
                <Link
                  to={`${localLinkResolver}/${
                    !!isForum ? `euraceconnect/forum-privacy-policy` : 'privacy'
                  }`}
                >
                  {' '}
                  {footerDocData.privacy_policy}{' '}
                </Link>
              </span>
              {' || '}
              <span>
                <Link to={`${localLinkResolver}/disclaimer`}> {footerDocData.disclaimer} </Link>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default withPrismicPreview(Footer, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
