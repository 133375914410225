/* eslint-disable react/jsx-props-no-spreading */
import { Formik } from 'formik';
import { navigate } from 'gatsby';
import React, { useEffect, useState, useRef } from 'react';
import { Container, Modal, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import countriesData from '../../../constants/countries.json';
import BasicPersonalDataForm from './BasicPersonalDataForm-component';
import { useCurrentProfileData } from '../../../utils/useCurrentProfileData';
import { AfterSubmitModal } from '../../../commons/AfterSubmitModal';

const isBrowser = typeof window !== 'undefined';

function AfterRegisteringForm({ firebaseAuth, basicFormVariablesFromPrismic, firestore }) {
  const inputRef = useRef(null);
  const [countriesList, setCountriesList] = useState([]);
  const [showFinalSpinner, setShowFinalSpinner] = useState(true);
  const { userFromAuth, usersList } = useCurrentProfileData();
  const handleCloseFinalSpinner = () => setShowFinalSpinner(false);
  const handleShowFinalSpinner = () => {
    setShowFinalSpinner(true);
    setTimeout(() => {
      setShowFinalSpinner(false);
      // alert(
      //   'Something went wrong, please contact us by email info@enterprof.org',
      // )
    }, 1000);
  };
  // Fetch countries list from local json
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const countriesDataStr = JSON.stringify(countriesData);
      // @ts-ignore
      setCountriesList(countriesDataStr.split('"'));
      setTimeout(() => {
        if (inputRef?.current) {
          // @ts-ignore
          inputRef.current.className = 'd-block';
          handleCloseFinalSpinner();
        }
      }, 500);
    }
    return () => {
      isMounted = false;
      setCountriesList([]);
    };
  }, []);

  const handleAddUserData = async values => {
    // @ts-ignore
    if (usersList.some(user => user?.email === userFromAuth.email)) {
      // @ts-ignore
      alert('You have already registered with this email');
      return;
    }
    try {
      await firestore
        .collection('usersCollection')
        .add({
          uid: userFromAuth.uid,
          full_name: userFromAuth.displayName,
          email: userFromAuth.email,
          phone_number: values.phone_number,
          organization: values.organization,
          website: values.website,
          country: values.country,
          city: values.city,
          street: values.street,
          postal_code: values.postal_code,
          pref_lang: values.pref_lang,
          initial_registration_date: Date.now(), // authorization date
          currentStatus: 'Portfolio not submitted', // || "Portfolio under review", "Portfolio reviewed"
          // , "Registered" , "Not registered"
          //
          educator: true,
          cp_any_institution: false,
          cp_acc_agency: false,
          academic_committee_member: false,
          qa_committee_member: false,
          monitoring_committee_member: false,
          expert_committee_member: false,
          president: false,
          sec: false,
          tax_number: '',
          academic_position: '',
          administrative_position: '',
          knowledge_domain: '',
          academic_degree: '',
          pedagogical_experience: '',
          motivation: '',
          //
          courses: '',
          committees_membership: '',
          pdp: '',
          conferences: '',
          higher_education_institution: '',
          pedagogical_experience_other: '',
          internship: '',
          professional_associations_membership: '',
          researcher_ID: '',
          awards: '',
          philosophy: '',
          portfolio_complete: false,
          registered_on_date: '', // submitted portfolio
          ///
          innovations: '',
          time_managment: '',
          effective_interaction: '',
          learning_interactivity: '',
          systems_analysis: '',
          psychology_communication: '',
          interaction: '',
          sustainable_development: '',
          digital_education: '',
          problem_project_practice_oriented_learning: '',
          outcomes_assessment: '',
          course_design: '',
          innovation_process: '',
          lifelong_learning: '',
          facebook_link: '',
          linkedin_link: '',
          instagram_link: '',
          twitter_link: '',
          personal_website_link: '',
          acceptedTerms: false,
          mcm1: '',
          mcm2: '',
          mcm3: '',
          mcms: '',
          to_be_reviewed: false,
          under_review: false,
          requestID: '',
        })
        .then(ref => {
          // https://firebase.google.com/docs/auth/web/manage-users#update_a_users_profile
        });
      setTimeout(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }, 500);
    } catch (error) {
      console.error(error);
    }
  };

  const afterRegisteringInitialValues = {
    full_name: userFromAuth?.displayName ?? 'Could`not get users data',
    email: userFromAuth?.email ?? 'Could`not get users data',
    /* 3* */ phone_number: '',
    /* 4* */ organization: '',
    /* 5* */ website: '',
    /* 6* */ country: '',
    /* 7* */ city: '',
    /* 8 */ street: '',
    /* 9 */ postal_code: '',
    /* 10* */ pref_lang: 'English',
  };

  return (
    // @ts-ignore
    <Container ref={inputRef} className="d-none">
      <div className="personal-data-confirmation-wrapper">
        <h4>To continue, please complete your data:</h4>
        {/* After Registering Form */}
        <Formik
          enableReinitialize
          initialValues={afterRegisteringInitialValues}
          isSubmitting
          validationSchema={Yup.object().shape({
            full_name: Yup.string().required('Required'),
            organization: Yup.string().required('Required'),
            website: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address`').required('Required'),
            phone_number: Yup.number().typeError('Must be a number'),
            country: Yup.string()
              .required('Required')
              .oneOf(countriesList, 'Please enter valid country name in English'),
            city: Yup.string().required('Required'),
            street: Yup.string().required('Required'),
            postal_code: Yup.string().required('Required'),
            pref_lang: Yup.string().required('Required'),
          })}
          onSubmit={handleAddUserData}
        >
          {props => (
            <BasicPersonalDataForm
              withTooltips={undefined}
              FirstFormTitle={undefined}
              SecondFormTitle={undefined}
              className={undefined}
              saveButtonLabel={undefined}
              firstSubTitle={undefined}
              secondSubTitle={undefined}
              saveForm={undefined}
              cancelButtonLabel="Logout"
              submitButtonLabel="Continue"
              firebaseAuth={firebaseAuth}
              mainFormVars={basicFormVariablesFromPrismic.items}
              cancelAction={() => {
                firebaseAuth.signOut();
                navigate('/login');
              }}
              {...props}
            />
          )}
        </Formik>
      </div>

      <AfterSubmitModal
        showFinalSpinner={showFinalSpinner}
        handleCloseFinalSpinner={handleCloseFinalSpinner}
      />
    </Container>
  );
}

export default AfterRegisteringForm;
