export const formTitle = 'Registration Form';
export const agencyFormTitle = 'ENAEE Agency Registration Form';
export const formData = [
  //Participant Name
  {
    sectionSubtitle: 'Participant Name',
    fields: [
      {
        title: 'First Name',
        required: true,
        name: 'first_name',
        form_type: 'text',
        placeholder: 'Enter your first name',
      },
      {
        title: 'Last Name',
        required: true,
        name: 'last_name',
        form_type: 'text',
        placeholder: 'Enter your last name',
      },
      {
        title: 'Your name as you want it to appear on your certificate of attendance',
        required: true,
        name: 'certificate_name',
        form_type: 'text',
        placeholder: 'Enter your name for the certificate',
      },
    ],
  },
  //Affiliation
  {
    sectionSubtitle: 'Affiliation',
    fields: [
      {
        title: 'Position',
        required: true,
        name: 'position',
        form_type: 'text',
        placeholder: 'Enter your position',
      },
      {
        title: 'Organisation',
        required: true,
        name: 'organisation',
        form_type: 'text',
        placeholder: 'Enter your organisation',
      },
      {
        title: 'Educational Program(s) you represent (if any)',
        required: false,
        name: 'educational_program',
        form_type: 'text',
        placeholder: 'Enter the educational program',
      },
    ],
  },
  //Address
  {
    sectionSubtitle: 'Address',
    fields: [
      {
        title: 'City',
        required: true,
        name: 'city',
        form_type: 'text',
        placeholder: 'Enter your city',
      },
      {
        title: 'Country',
        required: true,
        name: 'country',
        form_type: 'text',
        placeholder: 'Enter your country',
      },
      {
        title: 'E-mail',
        required: true,
        name: 'email',
        form_type: 'email',
        placeholder: 'Enter your email',
      },
      {
        title: 'Phone Number',
        required: false,
        name: 'phone_number',
        form_type: 'text',
        placeholder: 'Enter your phone number',
      },
      {
        title: 'VAT',
        required: false,
        name: 'vat',
        form_type: 'text',
        placeholder: 'Enter your VAT number',
      },
    ],
  },
  //Visa
  {
    sectionSubtitle: 'Visa',
    fields: [
      {
        title: 'Do you need assistance with Visa support letter?',
        required: true,
        name: 'visa',
        form_type: 'radio_option',
        placeholder: 'Yes, No',
      },
    ],
  },
  //Co-organizer´s institutional affiliation
  {
    sectionSubtitle: 'Attendance Status',
    fields: [
      {
        title: 'Co-organizer´s institutional affiliation',
        required: true,
        name: 'co_organizer_affiliation',
        form_type: 'select_option',
        placeholder:
          'ENTER active member,ISEL staff member,LACCEI active member,SEFI active member,OE active member,None',
        description: '*10% discount applies',
      },
      // file upload
      {
        title: 'Upload any document to evidence your affiliation to one of the co-organizers',
        required: false,
        name: 'evidence_document',
        form_type: 'file_upload',
        placeholder: 'Upload your iPEER certificate',
        description:
          'Provide evidence of your affiliation with institution that is an active member of one of the co-organizers of the event',
      },
      {
        title: 'International engineer educator registered (iPEER)',
        required: false,
        name: 'iPEER',
        form_type: 'text',
        placeholder: 'Enter your iPEER number',
        description:
          '*100 Euro discount applies to iPEER. Verify your Register number (https://enterprof.org/enter-registered-educators) or start the application process (https://enterprof.org/how-to-apply-educators) and insert your Request ID',
      },

      {
        title:
          'Indicate the main areas of your interest within the Forum Networking opportunities for the EUR-ACE accredited programs:',
        required: true,
        name: 'main_areas_of_interest',
        form_type: 'checkbox_options',
        placeholder: [
          {
            title: 'Double and joint degrees',
          },
          {
            title: 'Student mobility',
          },
          {
            title: 'Joint educational projects and initiatives',
          },
          {
            title: 'Collaborative research opportunities',
          },
          {
            title: 'Increasing employability opportunities for graduates',
          },
          {
            title: 'MOUs and cooperation agreements in general',
          },
          {
            title: 'Knowledge sharing and best practices',
          },
          {
            title: 'Global and Regional Visibility',
          },
          {
            title: 'Faculty development and collaboration',
          },
          {
            title: 'Alignment with industry needs',
          },
          {
            title: 'Other',
          },
        ],
      },
    ],
  },
  //Meals and coffee breaks
  {
    sectionSubtitle: 'Meals and coffee breaks',
    sectionSubtitle_description:
      'Coffee-breaks, lunches and Gala dinner indicated in the event agenda are included in the corresponding registration fee',
    fields: [
      {
        title: 'Are You Planning to Attend Gala Dinner (29/05/2025)?',
        required: true,
        name: 'gala_dinner',
        form_type: 'radio_option',
        placeholder: 'Yes, No',
      },
      {
        title: 'Indicate any dietary restrictions we should take into account for catering',
        required: true, // not required // Total Amount including discount(s)
        name: 'dietary_restrictions',
        form_type: 'checkbox_options',
        placeholder: [
          { title: 'None' },
          { title: 'Vegetarian' },
          { title: 'Vegan' },
          { title: 'Gluten-free' },
          { title: 'Dairy-free' },
          { title: 'Pescatarian' },
          { title: 'Other' },
        ],
      },
    ],
  },
  //Registration Fee
  {
    sectionSubtitle: 'Registration Fee',
    fields: [
      {
        title: '',
        required: false,
        name: 'registration_fee',
        form_type: 'radio_option',
        placeholder: [
          {
            title: 'Forum Delegate (29-30/05/2025)',
            labelSubTitle:
              'ONLY for representatives of the institutions with EUR-ACE accredited programs',
            price: '€ 500.00',
          },
          {
            title: 'Forum Delegate + EUR-ACE Preparation Workshop (27-30/05/2025)',
            price: '€ 1,200.00',
          },
          {
            title: 'Forum Delegate + EUR-ACE Experts Workshop (27-30/05/2025)',
            price: '€ 2,500.00',
          },
          {
            title: 'Open Day Delegate (29/05/2025)',
            price: '€ 500.00',
          },
          {
            title: 'Open Day Delegate + EUR-ACE Preparation Workshop (27-29/05/2025)',
            price: '€ 1,200.00',
          },
          {
            title: 'Open Day Delegate + EUR-ACE Experts Workshop (27-30/05/2025)',
            price: '€ 2,500.00',
          },
          {
            title: 'EUR-ACE Preparation Workshop (27-28/05/2025)',
            price: '€ 700.00',
          },
          {
            title: 'EUR-ACE Experts Workshop (27-30/05/2025)',
            price: '€ 2,000.00',
          },
        ],
      },
    ],
  },
];
export const agencyFormData = [
  //ENAEE accreditation agency you represent
  {
    sectionSubtitle: 'ENAEE accreditation agency you represent',
    fields: [
      {
        title: 'Agency name',
        required: true,
        name: 'agency_name',
        form_type: 'text',
        placeholder: 'Enter your agency name',
      },
    ],
  },
  //Address
  {
    sectionSubtitle: 'Address',
    fields: [
      {
        title: 'Country',
        required: true,
        name: 'country',
        form_type: 'text',
        placeholder: 'Enter country',
      },
      {
        title: 'City',
        required: true,
        name: 'city',
        form_type: 'text',
        placeholder: 'Enter city',
      },
      {
        title: 'Address',
        required: true,
        name: 'address',
        form_type: 'text',
        placeholder: 'Enter address',
      },
      {
        title: 'Zip code',
        required: true,
        name: 'zip_code',
        form_type: 'text',
        placeholder: 'Enter zip code',
      },
      {
        title: 'VAT number',
        required: true,
        name: 'vat',
        form_type: 'text',
        placeholder: 'Enter your VAT number',
      },
    ],
  },
  //Delegates info
  {
    sectionSubtitle: 'Delegates info',
    fields: [
      {
        title: 'How many delegates will participate from your agency',
        required: true,
        name: 'how_many_delegates',
        form_type: 'select_option',
        placeholder: '1,2,3,4,5',
        description: 'The registration fee for a group of 3-5 delegates has 10% discount',
      },
      {
        title: 'First Name',
        required: true,
        name: 'first_name',
        form_type: 'text',
        placeholder: 'Enter your first name',
        description_on_top: 'Main contact Person',
      },
      {
        title: 'Last Name',
        required: true,
        name: 'last_name',
        form_type: 'text',
        placeholder: 'Enter your last name',
      },
      {
        title: 'Position',
        required: true,
        name: 'position',
        form_type: 'text',
        placeholder: 'Enter your position',
      },
      {
        title: 'E-mail',
        required: true,
        name: 'email',
        form_type: 'email',
        placeholder: 'Enter your email',
      },
      {
        title: 'Phone Number',
        required: false,
        name: 'phone_number',
        form_type: 'text',
        placeholder: 'Enter your phone number',
      },
    ],
  },
  //Visa
  {
    sectionSubtitle: 'Visa',
    fields: [
      {
        title:
          'Please indicate how many of your delegates need assistance with visa support letter',
        required: true,
        name: 'agency_v_support_letter',
        form_type: 'select_option',
        placeholder: 'None,1,2,3,4,5',
      },
      // {
      //   title: 'Name as in passport',
      //   required: true,
      //   name: 'agency_v_passport_name',
      //   form_type: 'text',
      //   placeholder: 'Enter your name as in passport',
      //   description_on_top:
      //     'Please provide the following data to enable the organizing committee to issue a visa support letter for your delegates',
      // },
      // {
      //   title: 'Date of birth (DD/MM/YY)',
      //   required: true,
      //   name: 'agency_v_date_of_birth',
      //   form_type: 'text',
      //   placeholder: 'Enter your date of birth',
      // },
      // {
      //   title: 'Passport number',
      //   required: true,
      //   name: 'agency_v_passport_number',
      //   form_type: 'text',
      //   placeholder: 'Enter your passport number',
      // },
      // {
      //   title: 'Passport expiration date (DD/MM/YY)',
      //   required: true,
      //   name: 'agency_v_passport_expiration_date',
      //   form_type: 'text',
      //   placeholder: 'Enter your passport expiration date',
      // },
    ],
  },
  //Meals and coffee breaks
  {
    sectionSubtitle: 'Meals and coffee breaks',
    sectionSubtitle_description:
      'Coffee-breaks, lunches and Gala dinner indicated in the event agenda are included in the corresponding registration fee',
    fields: [
      {
        title: 'Are You Planning to Attend Gala Dinner (29/05/2025)?',
        required: true,
        name: 'gala_dinner',
        form_type: 'radio_option',
        placeholder: 'Yes, No',
      },
      {
        title: 'Indicate any dietary restrictions we should take into account for catering',
        required: true,
        name: 'dietary_restrictions',
        form_type: 'checkbox_options',
        placeholder: [
          { title: 'None' },
          { title: 'Vegetarian' },
          { title: 'Vegan' },
          { title: 'Gluten-free' },
          { title: 'Dairy-free' },
          { title: 'Pescatarian' },
          { title: 'Other' },
        ],
      },
    ],
  },
];
