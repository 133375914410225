import React, { useState } from 'react';
import axios from 'axios';
import { Form } from 'formik';

import { ConnectedFocusError } from 'focus-formik-error';
import { Formik } from 'formik';
import * as Yup from 'yup';

//components
import { Spinner, Modal, Container } from 'react-bootstrap';
import { AcademicPositionOption, MessageInput, TextInput } from '../InputFields-components';

const PersonalDataFormWStripe = ({
  userId,
  firestore,
  full_name,
  email,
  country,
  formWStripeVariables,
  location,
  handleClose,
  usersDataObject,
}) => {
  const [showFinalSpinner, setShowFinalSpinner] = useState(false);

  const initialValues = usersDataObject || {
    full_name: full_name || '',
    email: email || '',
    country: country || '',
    tax_number: '',
    higher_education_institution: '',
    academic_position: '',
    administrative_position: '',
    knowledge_domain: '',
    academic_degree: '',
    pedagogical_experience: '',
    motivation: '',
  };

  const handleSaveAndClose = async values => {
    try {
      await pureHandleUserDataUpdate(values);
      await handleClose();
      handleShowFinalSpinner();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseFinalSpinner = () => setShowFinalSpinner(false);
  const handleShowFinalSpinner = () => {
    setShowFinalSpinner(true);
    setTimeout(() => {
      setShowFinalSpinner(false);
      // alert(
      //   'Something went wrong, please contact us by email info@enterprof.org',
      // )
    }, 3000);
  };

  function AfterSubmitModal() {
    return (
      <>
        <Modal
          animation={false}
          fullscreen
          show={showFinalSpinner}
          onHide={handleCloseFinalSpinner}
        >
          <Spinner size="sm" animation="border" variant="info" />
        </Modal>
      </>
    );
  }

  const handleRedirectToStripe = async values => {
    // console.log('values inside Handle Redirect to stripe: >>', values)

    try {
      // Send data to the function,
      // await the result.
      const result = await axios.post('/api/personal-application-fee', {
        full_name: values.full_name || 'no full_name information',
        country: values.country || 'no country information',
        email: email || 'no email information', //also can take the email value from value.email. But then I have to add this field in prismic
        isSecondPayment: false, // stripe sent it as a "String"
        cancelUrl: `${location.origin}/personal-page`, //?payment=cancelled
        //can create canceled page where we can ask, "Would you like to continue to correct your form submission or to start from beginning"
        successUrl: `${location.origin}/p-success/?session_id={CHECKOUT_SESSION_ID}`,
      });
      window.location = result.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const pureHandleUserDataUpdate = async values => {
    try {
      await firestore
        .collection('usersCollection')
        .doc(userId)
        .update({
          tax_number: values.tax_number || '',
          higher_education_institution: values.higher_education_institution || '',
          academic_position: values.academic_position || '',
          administrative_position: values.administrative_position || '',
          knowledge_domain: values.knowledge_domain || '',
          academic_degree: values.academic_degree || '',
          pedagogical_experience: values.pedagogical_experience || '',
          motivation: values.motivation || '',
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormikSubmit = async (values, actions) => {
    handleShowFinalSpinner();
    try {
      await handleRedirectToStripe(values);
      await pureHandleUserDataUpdate(values);
    } catch (error) {
      console.log(error);
    }
  };

  const personalFormTitle =
    formWStripeVariables?.primary?.educator_form_with_stripe_title?.text || 'Application Form';
  const cancelBtn = formWStripeVariables?.primary?.previous__button_label || '';
  const goToPayBtn = formWStripeVariables?.primary?.proceed_to_the_payment__button_label || '';

  return (
    <div>
      <Container className="position-relative">
        <h1 className="collection-page-title">{personalFormTitle}</h1>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          isSubmitting={true}
          validationSchema={Yup.object().shape({
            full_name: Yup.string(),
            country: Yup.string(), // not editable
            higher_education_institution: Yup.string(),
            academic_position: Yup.string().required('Required'),
            administrative_position: Yup.string(),
            knowledge_domain: Yup.string()
              .required('Required')
              .max(1999, 'Have to be not more than 2000 characters'),
            academic_degree: Yup.string().required('Required'),
            motivation: Yup.string().required('Required'),
            pedagogical_experience: Yup.string().required('Required'),
          })}
          onSubmit={handleFormikSubmit}
        >
          {props => (
            <Form className="main-form">
              <ConnectedFocusError />
              {formWStripeVariables?.items.map(f => {
                if (f.field_type === 'One line Input' && f.title === 'Full Name') {
                  return (
                    <h5 key={f.title} className=" mb-4">
                      {f.title}
                      {f.required && <span className="text-danger"> *</span>}
                      {f.description && <p className="mb-0 mt-1">{f.description}</p>}
                      <br />
                      <TextInput
                        disabled={true}
                        name={f.name ? f.name : 'enter_name_variable'}
                        type={f.form_type ? f.form_type : 'enter_form_type'}
                        placeholder={f.placeholder ? f.placeholder : 'enter_placeholder'}
                      />
                    </h5>
                  );
                } else if (
                  f.field_type === 'One line Input' &&
                  f.title === 'Country' &&
                  country === 'Portugal'
                ) {
                  return (
                    <h5 key={f.title} className=" mb-4">
                      {f.title}
                      {f.required && <span className="text-danger"> *</span>}
                      {f.description && <p className="mb-0 mt-1">{f.description}</p>}
                      <br />
                      <TextInput
                        disabled={true}
                        name={f.name ? f.name : 'enter_name_variable'}
                        type={f.form_type ? f.form_type : 'enter_form_type'}
                        placeholder={f.placeholder ? f.placeholder : 'enter_placeholder'}
                      />
                      NIF
                      <TextInput name="tax_number" type="text" placeholder="123456789" />
                    </h5>
                  );
                } else if (
                  f.field_type === 'One line Input' &&
                  f.title === 'Country' &&
                  country !== 'Portugal'
                ) {
                  return (
                    <h5 key={f.title} className=" mb-4">
                      {f.title}
                      {f.required && <span className="text-danger"> *</span>}
                      {f.description && <p className="mb-0 mt-1">{f.description}</p>}
                      <br />
                      <TextInput
                        disabled={true}
                        name={f.name ? f.name : 'enter_name_variable'}
                        type={f.form_type ? f.form_type : 'enter_form_type'}
                        placeholder={f.placeholder ? f.placeholder : 'enter_placeholder'}
                      />
                    </h5>
                  );
                } else if (
                  f.field_type === 'One line Input' &&
                  f.title !== 'Country' &&
                  f.title !== 'Full Name'
                ) {
                  return (
                    <h5 key={f.title} className=" mb-4">
                      {f.title}
                      {f.required && <span className="text-danger"> *</span>}
                      {f.description && <p className="mb-0 mt-1">{f.description}</p>}
                      <br />
                      <TextInput
                        name={f.name ? f.name : 'enter_name_variable'}
                        type={f.form_type ? f.form_type : 'enter_form_type'}
                        placeholder={f.placeholder ? f.placeholder : 'enter_placeholder'}
                      />
                    </h5>
                  );
                } else if (f.field_type === 'Radio Input') {
                  const radiosArray = f.radio_options.split(',');
                  return (
                    <div key={f.title}>
                      <h5 id="academic-degree-group">
                        {f.title}
                        {f.required && <span className="text-danger "> *</span>}
                        <AcademicPositionOption name={f.name ? f.name : 'enter_name_variable'}>
                          {radiosArray &&
                            radiosArray.map((option, key) => {
                              return (
                                <option key={key} value={option}>
                                  {option}
                                </option>
                              );
                            })}
                        </AcademicPositionOption>
                      </h5>
                    </div>
                  );
                } else if (f.field_type === 'Options Input') {
                  const optionsArray = f.radio_options.split(',');

                  return (
                    <h5 key={f.title} className=" mb-4">
                      {f.title} {f.required && <span className="text-danger "> *</span>}
                      <AcademicPositionOption name={f.name ? f.name : 'enter_name_variable'}>
                        {optionsArray &&
                          optionsArray.map((option, key) => {
                            return (
                              <option key={key} value={option}>
                                {option}
                              </option>
                            );
                          })}
                      </AcademicPositionOption>
                    </h5>
                  );
                } else if (f.field_type === 'Long Input') {
                  return (
                    <h5 key={f.title} className=" mb-4">
                      {f.title}
                      {f.required && <span className="text-danger"> *</span>}
                      {f.description && <p className="mb-0 mt-1">{f.description}</p>}

                      <MessageInput
                        name={f.name ? f.name : 'enter_name_variable'}
                        type={f.form_type ? f.form_type : 'enter_form_type'}
                        placeholder={f.placeholder ? f.placeholder : 'enter_placeholder'}
                      />
                    </h5>
                  );
                }
                return null;
              })}
              <br />
              <div className="d-flex flex-row mt-2">
                <button
                  onClick={() => {
                    handleSaveAndClose(props.values);
                  }}
                  id="go_to_step_2"
                  type="button"
                >
                  {cancelBtn}
                </button>
                <button id="submit" type="submit">
                  {goToPayBtn}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
      <AfterSubmitModal />
    </div>
  );
};

export default PersonalDataFormWStripe;
