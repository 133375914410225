import * as React from 'react';
import { graphql } from 'gatsby';
import * as XLSX from 'xlsx';
import axios from 'axios';
import './_tableStyles.scss';

export const RTable = ({ slice }) => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(slice.primary.link_to_excel_document.url, {
          responseType: 'arraybuffer',
        });
        const workbook = XLSX.read(response.data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        setData(parsedData);
      } catch (error) {
        console.error('Error fetching the Excel file:', error);
      }
    };

    fetchData();
  }, [slice.primary.link_to_excel_document.url]);

  return (
    <section className={`content-section r-table`}>
      <table>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export const query = graphql`
  fragment ForumPageDataBodyReusableTable on PrismicForumPageDataBodyRtable {
    primary {
      table_title {
        text
      }
      link_to_excel_document {
        url
      }
    }
  }
`;
