import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { CustomLink } from '../../utils/CustomLink';

export const Form = ({ slice }) => {
  return (
    <section className={`content-section `}>
      {slice?.primary?.next__button_label && <h2>{slice.primary.next__button_label}</h2>}
    </section>
  );
};

export const query = graphql`
  fragment ForumPageDataForm on PrismicForumPageDataBodyForm {
    id
    items {
      description
      field_type
      form_type
      name
      placeholder
      radio_options
      required
      title
    }
    primary {
      next__button_label
      previous__button_label
      privacy_message
      privacy_message_starter
      privacy_page_link {
        url
      }
      proceed_to_the_payment__button_label
      save__button_laber
      start__button_laber
    }
    slice_label
    slice_type
  }
`;
