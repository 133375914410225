import axios from 'axios';
import firebase from 'firebase';
import { ConnectedFocusError } from 'focus-formik-error';
import { Form, Field, Formik } from 'formik';
import queryString from 'query-string';
import { Link, graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { useEffect, useState, useMemo } from 'react';
import { Button, Spinner, Modal, Container } from 'react-bootstrap';
import * as Yup from 'yup';
import { defaultLanguage } from '../../prismic-configuration';
import {
  MessageInput,
  MyCheckbox,
  TextInput,
} from '../components/Form-components/InputFields-components';
import RequiredAlert from '../components/Form-components/RequiredAlert';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { storage } from '../firebase';
import { linkResolver } from '../utils/LinkResolver';
import {
  formTitle,
  formData,
  agencyFormTitle,
  agencyFormData,
} from '../forumUtils/registrationConstants.js';
import {
  AGENCY_FORM_CONSTANTS,
  INDIVIDUAL_FORM_CONSTANTS,
} from '../forumUtils/forumFormConstants.js';
import { priceMap, AffiliateDiscountedPriceMap, priceAgency } from '../constants/constants.js';
import { useDropzone } from 'react-dropzone';

function LocalForm({ formData, props }) {
  const { fields, sectionSubtitle, sectionSubtitle_description } = formData;
  const [progress, setProgress] = useState(0);
  const [pdfDocUrl, setPdfDocUrl] = useState('');
  // file name
  const [pdfDocName, setPdfDocName] = useState('');
  const clearFileInput = e => {
    e.target.previousSibling.value = '';
    setProgress(0);
    setPdfDocUrl('');
    setPdfDocName('');
  };

  const clearFileInputKey = e => {
    if (e.key === 'Enter') {
      e.target.previousSibling.value = '';
      setProgress(0);
      setPdfDocUrl('');
      setPdfDocName('');
    }
  };

  const handleFileUpload = e => {
    const pdfDoc = e?.target?.files[0] ? e.target.files[0] : e?.[0];
    const pdfDocName = pdfDoc?.name;
    if (pdfDoc) {
      const uploadTask = storage.ref(`forum_documents/${pdfDocName}`).put(pdfDoc);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const snapshotProgress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          );
          setProgress(snapshotProgress);
        },
        error => {
          console.info(error);
        },
        () => {
          storage
            .ref('forum_documents')
            .child(pdfDocName)
            .getDownloadURL()
            .then(storageUrl => {
              setPdfDocUrl(storageUrl);
              setPdfDocName(pdfDocName);
              props.setFieldValue('evidence_document', {
                pdfDocUrl: storageUrl,
                pdfDocName: pdfDocName,
              });

              props.setFieldValue('pdf_name', pdfDocName);
            });
        },
      );
    } else {
      alert('No File was selected, please try again');
    }
  };
  const {
    acceptedFiles,
    isDragActive,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.png'],
      'application/pdf': ['.pdf'],
    },
    maxSize: 5242880, // 5MB
    maxFiles: 1,
    onDrop: acceptedFiles => {
      handleFileUpload(acceptedFiles);
      props.setFieldValue('portfolio_file', acceptedFiles);
    },
  });

  return (
    <>
      <h4 className="text-primary mt-5">{sectionSubtitle}</h4>
      {sectionSubtitle_description && (
        <p className="text-muted mt-2">{sectionSubtitle_description}</p>
      )}

      <hr />
      {fields.map(f => {
        const { title, required, name, description, placeholder, form_type, description_on_top } =
          f;
        if (form_type === 'text') {
          return (
            <h5 className=" mb-4" key={title + name}>
              {description_on_top && (
                <>
                  {description_on_top.includes('Delegate') && <hr className="mt-0 mb-0" />}
                  <p className="text-muted mt-2 mb-0">{description_on_top}</p>
                  <hr className="mt-2 mb-2" />
                </>
              )}

              {title}
              {required && <span className="text-danger"> *</span>}
              <br />
              <TextInput
                name={name}
                type="enter_form_type"
                placeholder={placeholder}
                required={required}
              />
              {description && <p className="text-muted mt-2">{description}</p>}
            </h5>
          );
        }
        if (form_type === 'email') {
          return (
            <h5 className=" mb-4" key={title + name}>
              {title}
              {required && <span className="text-danger"> *</span>}
              <br />
              <TextInput name={name} type="email" placeholder={placeholder} required={required} />
            </h5>
          );
        }
        if (form_type === 'checkbox_options') {
          return (
            <div key={name}>
              <h5>
                {title}
                {required && <span className="text-danger"> *</span>}
              </h5>
              <div className="form-check ps-4 pb-3">
                {/* // if option.title === Other add input field */}
                {placeholder?.map((option, key) => {
                  return (
                    <div key={name + key}>
                      <div
                        id={option.title}
                        className="checkbox-wrapper"
                        onClick={() => {
                          // check if the option is selected
                          if (props.values[name]?.includes(option.title)) {
                            // if selected, remove from the list
                            props.setFieldValue(
                              name,
                              props.values[name].filter(item => item !== option.title),
                            );
                            if (props.values[name].length <= 1) {
                              props.setFieldValue(name, '');
                            }
                            // remove class active from the selected option
                            const selectedOption = document.getElementById(option.title);
                          }
                          // if not selected, add to the list
                          else {
                            props.setFieldValue(name, [...props.values[name], option.title]);
                            // add class active to the selected option
                            const selectedOption = document.getElementById(option.title);
                          }
                        }}
                      >
                        <label
                          key={name + key}
                          htmlFor={name}
                          className="form-check-label fs-6 text d-block "
                        >
                          <Field
                            className="form-check-input"
                            name={name}
                            type={'checkbox'}
                            value={option.title}
                          />
                          {option.title}
                        </label>
                        {option.labelSubTitle && (
                          <p className="text-muted font-italic">{option.labelSubTitle}</p>
                        )}
                      </div>
                      {option.title === 'Other' && (
                        <TextInput
                          name={`${name}_other`}
                          type="enter_form_type"
                          placeholder={'Please provide your response'}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
        if (form_type === 'radio_option') {
          if (name === 'registration_fee') {
            return (
              <div key={name}>
                <h5>
                  {title}
                  {required && <span className="text-danger"> *</span>}
                </h5>
                <div className="form-check ps-4 pb-3 registration-fee-wrapper">
                  {placeholder?.map((option, key) => {
                    return (
                      <div
                        className="registration-fee-box"
                        key={name + key}
                        id={option.title}
                        onClick={() => {
                          // check if the option is selected
                          if (props.values[name] === option.title + ' - ' + option.price) {
                            return;
                          }
                          // if not selected, add to the list
                          else {
                            props.setFieldValue(name, option.title + ' - ' + option.price);
                            // add class active to the selected option
                            const allOptions = document.querySelectorAll('.registration-fee-box');
                            allOptions.forEach(option => option.classList.remove('active'));
                            const selectedOption = document.getElementById(option.title);
                            selectedOption.classList.add('active');
                          }
                        }}
                      >
                        <label
                          htmlFor={name}
                          className="form-check-label fs-6 text d-block registration-fee-label"
                        >
                          <Field
                            className="form-check-input mt-2"
                            name={name}
                            type={'radio'}
                            required={required}
                          />
                          {option.title} <br />
                          <span className="text-muted font-italic text-smaller">
                            {option.labelSubTitle}
                          </span>
                          {option.price && (
                            <span className="fs-5 registration-fee-price">{option.price}</span>
                          )}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }
          const radiosArray = placeholder?.split(',');

          return (
            <div key={name}>
              <h5 id={name}>
                {title}
                {required && <span className="text-danger"> *</span>}
              </h5>
              <div className="form-check ps-4 pb-3" role="group" aria-labelledby={name}>
                {radiosArray?.map((option, key) => {
                  return (
                    <label
                      key={name + key}
                      htmlFor={name}
                      className="form-check-label fs-6 text d-block mt-4"
                    >
                      <Field
                        className="form-check-input"
                        name={name}
                        type={'radio'}
                        value={option}
                        required={required}
                      />
                      {option}
                    </label>
                  );
                })}
              </div>
              {description && <p className="text-muted">{description}</p>}
            </div>
          );
        }
        if (form_type === 'select_option') {
          const optionsArray = placeholder.split(',');
          return (
            <h5 key={name} className="mb-4">
              {title}
              {required && <span className="text-danger"> *</span>}
              <select
                name={name}
                className="form-select mt-4"
                onChange={e => {
                  props.setFieldValue(name, e.target.value);
                }}
                value={props.values[name]}
                required={required}
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                {optionsArray?.map((option, key) => {
                  return (
                    <option key={name + key} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
              {description && <p className="text-muted mt-2">{description}</p>}
            </h5>
          );
        }
        if (form_type === 'file_upload') {
          const baseStyle = {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderWidth: 2,
            borderRadius: 2,
            paddingTop: 50,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            height: '130px',
            position: 'relative',
            top: '-100px',
            marginBottom: '-50px',
          };

          const focusedStyle = {
            borderColor: '#2196f3',
          };

          const acceptStyle = {
            borderColor: '#00e676',
          };

          const rejectStyle = {
            borderColor: '#ff1744',
          };

          const style = useMemo(
            () => ({
              ...baseStyle,
              ...(isFocused ? focusedStyle : {}),
              ...(isDragAccept ? acceptStyle : {}),
              ...(isDragReject ? rejectStyle : {}),
            }),
            [isFocused, isDragAccept, isDragReject],
          );

          return (
            <h5 key={name} className="mb-4 position-relative">
              {title}
              {required && <span className="text-danger"> *</span>}
              <div
                style={{
                  opacity: `${!pdfDocName ? '1' : '0'}`,
                  pointerEvents: `${!pdfDocName ? 'initial' : 'none'}`,
                  transition: 'opacity 0.5s',
                }}
              >
                <div {...getRootProps({ style })} className="dropzone-accepter">
                  <input
                    {...getInputProps()}
                    onChange={e => {
                      handleFileUpload(e);
                    }}
                    className=""
                  />
                </div>
                <div {...getRootProps({ style })}>
                  {isDragAccept && <p>File ready to be uploaded</p>}
                  {isDragReject && <p>File will be rejected</p>}
                  {!isDragActive && <p>Drop file here or click to select one</p>}
                </div>
              </div>
              <div
                style={{
                  opacity: `${progress > 0 ? '1' : '0'}`,
                  marginTop: '100px',
                  position: 'absolute',
                  top: '0px',
                  left: '0',
                }}
              >
                <p style={{ minHeight: '36px' }}>{pdfDocName}</p>
                <progress value={progress} max="100" /> <span> {progress}%</span>
                <span
                  aria-label="clear-portfolio-file"
                  tabIndex="0"
                  role="button"
                  className="cross"
                  style={{ position: 'relative', top: '-5px', left: '10px' }}
                  onKeyPress={clearFileInputKey}
                  onClick={clearFileInput}
                />
              </div>
              {description && (
                <p
                  className="text-muted"
                  style={{
                    opacity: `${!pdfDocName ? '1' : '0'}`,
                    pointerEvents: `${!pdfDocName ? 'initial' : 'none'}`,
                    transition: 'opacity 0.5s',
                    marginTop: '-70px',
                  }}
                >
                  {description}
                </p>
              )}
            </h5>
          );
        }
        return <span />;
      })}
    </>
  );
}

function AfterSubmitModal({ showFinalSpinner, handleCloseFinalSpinner }) {
  return (
    <Modal animation={false} fullscreen show={showFinalSpinner} onHide={handleCloseFinalSpinner}>
      <Spinner size="sm" animation="border" variant="info" />
    </Modal>
  );
}

function ForumParticipantRegistration({ data }) {
  const isWindow = typeof window !== 'undefined';
  const firstFormValuesLocalStorage = isWindow
    ? localStorage.getItem('firstFormValues') || null
    : null;

  const [isToClean, setIsToClean] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('pending');
  const [paymentMessage, setPaymentMessage] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [isAgency, setIsAgency] = useState(null);
  const currentFormValues = isAgency ? AGENCY_FORM_CONSTANTS : INDIVIDUAL_FORM_CONSTANTS;
  const [alert_visibility, setAlert_visibility] = useState('invisible');
  const [total_amount, setTotal_amount] = useState(0);
  const [showFinalSpinner, setShowFinalSpinner] = useState(false);

  const isBrowser = typeof window !== 'undefined';

  const currentFormValuesLocalStorage =
    isWindow && !!isAgency
      ? agencyFormValuesLocalStorage
      : isWindow && !isAgency
      ? firstFormValuesLocalStorage
      : null;

  const [formValues, setFormValues] = useState(
    currentFormValuesLocalStorage
      ? JSON.parse(`${currentFormValuesLocalStorage}`)
      : currentFormValues,
  );
  // get params
  useEffect(() => {
    const params = queryString.parse(location.search);

    if (params.session_id) {
      setSessionId(params.session_id);
    }
    if (params.isAgency) {
      setIsAgency(params.isAgency === 'true');
    }
  }, []);

  // handle stripe after payment and send email on Success
  useEffect(() => {
    if (!sessionId) return;
    let isMounted = true;

    if (isMounted) {
      const params = queryString.parse(location.search);
      if (params.session_id) {
        const getFirstPaymentApplicant = async () => {
          try {
            const result = await axios.get('/api/forum-fee', {
              params: { session_id: sessionId },
            });
            setPaymentStatus('fulfilled');
            setPaymentMessage(result.data.message);
            // get local storage timestamp if exists
            const timestampEmailSent = localStorage.getItem(`timestampEmailSent${sessionId}`);
            // if the email was sent more than 5 mins ago, send it again
            // if (true) {
            const currentValues = isAgency
              ? JSON.parse(localStorage.getItem('agencyFormValues'))
              : JSON.parse(localStorage.getItem('firstFormValues'));
            if (!timestampEmailSent && currentValues?.email) {
              localStorage.setItem(`timestampEmailSent${sessionId}`, Date.now());
              await axios.post('/api/forum-registration-emails', {
                values: currentValues,
                requestID: result.data.requestID,
                uuid: result.data.uuid,
                isAgency,
              });
              // clear form data from local storage
              localStorage.removeItem('firstFormValues');
              localStorage.removeItem('agencyFormValues');
              localStorage.removeItem('isAgency');
            }
          } catch (error) {
            setPaymentStatus('failed');
            setPaymentMessage(error.response?.data?.message || error.message);
          }
        };

        getFirstPaymentApplicant();
      }
    }
    return () => {
      isMounted = false;
    };
    // Grab the session
  }, [sessionId]);

  // Success Modal End

  const handleSetTotalAmount = (values, isAgency) => {
    // check if the registration fee is selected
    if (values.registration_fee && !isAgency) {
      let selectedFee = Number(values.registration_fee.split(' € ')[1].replace(',', ''));

      // if co_organizer_affiliation is selected, minus 20 % from the total amount
      if (
        !!values.co_organizer_affiliation &&
        values.co_organizer_affiliation !== 'Please select' &&
        values.co_organizer_affiliation !== 'None'
      ) {
        selectedFee -= selectedFee * 0.1;
      }

      //if iPEER is selected, add 50 to the total amount
      if (!!values.iPEER) {
        selectedFee -= 100;
      }
      // set the total amount
      setTotal_amount(selectedFee);
    }
    if (values.registration_fee && isAgency) {
      let selectedFee = Number(values.registration_fee.split(' € ')[1].replace(',', ''));
      let totalPrice = selectedFee * values.how_many_delegates || 0;

      if (values.how_many_delegates > 2) {
        // if more that 2 delegates are selected, minus 10 % from the total amount
        totalPrice -= totalPrice * 0.1;
      }

      // set the total amount
      setTotal_amount(totalPrice);
    }
  };

  const handleMainAreasOfInterestOther = values => {
    const mainAreasOfInterestOther = document.querySelector(
      '[name="main_areas_of_interest_other"]',
    );
    if (mainAreasOfInterestOther) {
      if (values.main_areas_of_interest?.includes('Other')) {
        mainAreasOfInterestOther.parentElement.style.display = 'block';
      } else {
        mainAreasOfInterestOther.parentElement.style.display = 'none';
      }
    }
  };

  const handleDietaryRestrictionsOther = values => {
    const dietaryRestrictionsOther = document.querySelector('[name="dietary_restrictions_other"]');
    if (dietaryRestrictionsOther) {
      if (values.dietary_restrictions?.includes('Other')) {
        dietaryRestrictionsOther.parentElement.style.display = 'block';
      } else {
        dietaryRestrictionsOther.parentElement.style.display = 'none';
      }
    }
  };
  function handleAddPriceId(values) {
    let selectedFeeD = values.registration_fee.split('- € ')[0].replace(',', '');
    //if iPEER is selected, add 50 to the total amount
    if (
      !!values.co_organizer_affiliation &&
      values.co_organizer_affiliation !== 'None' &&
      values.co_organizer_affiliation !== 'Please select' &&
      !!values.iPEER
    ) {
      values.registration_fee_id = AffiliateDiscountedPriceMap[selectedFeeD.trim()];
    } else {
      values.registration_fee_id = priceMap[selectedFeeD.trim()];
    }
  }
  // dietary_restrictions_other name hide initially
  // and
  // main_areas_of_interest_other name hide initially
  useEffect(() => {
    // main_areas_of_interest_other name hide initially
    const mainAreasOfInterestOther = document.querySelector(
      '[name="main_areas_of_interest_other"]',
    );
    if (mainAreasOfInterestOther) {
      mainAreasOfInterestOther.parentElement.style.display = 'none';
    }

    // dietary_restrictions_other name hide initially
    const dietaryRestrictionsOther = document.querySelector('[name="dietary_restrictions_other"]');
    if (dietaryRestrictionsOther) {
      dietaryRestrictionsOther.parentElement.style.display = 'none';
    }

    // style if true fee field
    // const registrationFee = document.querySelectorAll('[name="registration_fee"]');
    // get value from the local storage if exists
    const firstFormValuesLocalStorage = localStorage.getItem('firstFormValues');
    if (firstFormValuesLocalStorage) {
      const firstFormValues = JSON.parse(firstFormValuesLocalStorage);
      if (firstFormValues.registration_fee) {
        const selectedFee = firstFormValues.registration_fee.split('- € ')[0].trim();
        const selectedOption = document.getElementById(selectedFee);
        selectedOption?.classList.add('active');
      }
    }
  }, []);

  // VISA FOR INDIVIDUAL => YES NO FLOW
  // state yesVisaFields
  const [yesVisaFields, setYesVisaFields] = useState([]);
  // if visa is selected, show the visa fields
  useEffect(() => {
    let visa_fields = [];
    if (formValues?.visa === 'Yes' && !isAgency) {
      visa_fields.push(
        {
          title: 'Name as in passport',
          required: true,
          name: 'visa_passport_name',
          form_type: 'text',
          placeholder: 'Enter your name as in passport',
          description_on_top:
            'Please fill out the following fields to enable the organizing committee to issue a visa support letter for you',
        },
        {
          title: 'Date of birth (DD/MM/YY)',
          required: true,
          name: 'visa_date_of_birth',
          form_type: 'text',
          placeholder: 'Enter your date of birth',
        },
        {
          title: 'Passport number',
          required: true,
          name: 'visa_passport_number',
          form_type: 'text',
          placeholder: 'Enter your passport number',
        },
        {
          title: 'Passport expiration date (DD/MM/YY)',
          required: true,
          name: 'visa_passport_expiration_date',
          form_type: 'text',
          placeholder: 'Enter your passport expiration date',
        },
      );
    }
    const mainVisaSelectorField = [
      {
        title: 'Do you need assistance with Visa support letter?',
        required: true,
        name: 'visa',
        form_type: 'radio_option',
        placeholder: 'Yes, No',
      },
    ];
    setYesVisaFields([...mainVisaSelectorField, ...visa_fields]);
  }, [formValues.visa]);
  formData.map((f, index) => {
    if (f.sectionSubtitle === 'Visa') {
      f.fields = yesVisaFields;
    }
  });
  // END OF VISA YES NO FLOW

  // AGENCY DELEGATES FLOW
  const [extraDelegates, setExtraDelegates] = useState([]);
  useEffect(() => {
    let delegates = [];
    if (formValues?.how_many_delegates && !!isAgency) {
      for (let i = 1; i < formValues?.how_many_delegates; i++) {
        delegates.push({
          title: `First Name (Delegate ${i + 1})`,
          required: true,
          name: `first_name_${i}`,
          form_type: 'text',
          placeholder: 'Enter your first name',
          description_on_top: `Delegate ${i + 1}`,
        });
        delegates.push({
          title: `Last Name (Delegate ${i + 1})`,
          required: true,
          name: `last_name_${i}`,
          form_type: 'text',
          placeholder: 'Enter your last name',
        });
        delegates.push({
          title: `Position (Delegate ${i + 1})`,
          required: true,
          name: `position_${i}`,
          form_type: 'text',
          placeholder: 'Enter your position',
        });
        delegates.push({
          title: `Email (Delegate ${i + 1})`,
          required: true,
          name: `email_${i}`,
          form_type: 'email',
          placeholder: 'Enter your email',
        });
        delegates.push({
          title: `Phone Number (Delegate ${i + 1})`,
          required: false,
          name: `phone_number_${i}`,
          form_type: 'text',
          placeholder: 'Enter your phone number',
        });
      }
    }
    const permanentDelegatesForm = [
      {
        title: 'How many delegates will participate from your agency',
        required: true,
        name: 'how_many_delegates',
        form_type: 'select_option',
        placeholder: '1,2,3,4,5',
        description: 'The registration fee for a group of 3-5 delegates has 10% discount',
      },
      {
        title: 'First Name',
        name: 'first_name',
        form_type: 'text',
        placeholder: 'Enter your first name',
        description_on_top: `Delegate 1 (Main Contact Person)`,
        required: true,
      },
      {
        title: 'Last Name',
        name: 'last_name',
        form_type: 'text',
        placeholder: 'Enter your last name',
        required: true,
      },
      {
        title: 'Position',
        name: 'position',
        form_type: 'text',
        placeholder: 'Enter your position',
        required: true,
      },
      {
        title: 'Email',
        name: 'email',
        form_type: 'email',
        placeholder: 'Enter your email',
        required: true,
      },
      {
        title: 'Phone Number',
        name: 'phone_number',
        form_type: 'text',
        placeholder: 'Enter your phone Number',
        required: false,
      },
    ];
    setExtraDelegates([...permanentDelegatesForm, ...delegates]);
  }, [formValues?.how_many_delegates]);

  agencyFormData.map((f, index) => {
    if (f.sectionSubtitle === 'Delegates info') {
      f.fields = extraDelegates;
    }
  });
  // END OF  AGENCY DELEGATES FLOW

  // AGENCY VISA SUPPORT LETTER FLOW
  const [agencyVisaSupportLetter, setAgencyVisaSupportLetter] = useState([]);
  useEffect(() => {
    let visaSupportLetter = [];
    if (formValues?.agency_v_support_letter && !!isAgency) {
      if (
        formValues?.agency_v_support_letter !== 'None' ||
        formValues?.agency_v_support_letter !== 'Please select'
      ) {
        for (let i = 0; i < formValues?.agency_v_support_letter; i++) {
          visaSupportLetter.push({
            title: `Passport Name `,
            required: true,
            name: `agency_v_passport_name_${i}`,
            form_type: 'text',
            placeholder: 'Enter your passport name',
            description_on_top: `-`,
          });
          visaSupportLetter.push({
            title: `Date of Birth `,
            required: true,
            name: `agency_v_date_of_birth_${i}`,
            form_type: 'text',
            placeholder: 'DD/MM/YY',
          });
          visaSupportLetter.push({
            title: `Passport Number `,
            required: true,
            name: `agency_v_passport_number_${i}`,
            form_type: 'text',
            placeholder: 'Enter your passport number',
          });
          visaSupportLetter.push({
            title: `Passport Expiration Date `,
            required: true,
            name: `agency_v_passport_expiration_date_${i}`,
            form_type: 'text',
            placeholder: 'DD/MM/YY',
          });
        }
      }
    }
    const mainVisaSupportSelector = [
      {
        title:
          'Please indicate how many of your delegates need assistance with visa support letter',
        required: true,
        name: 'agency_v_support_letter',
        form_type: 'select_option',
        placeholder: 'None,1,2,3,4,5',
      },
    ];
    setAgencyVisaSupportLetter([...mainVisaSupportSelector, ...visaSupportLetter]);
  }, [formValues?.agency_v_support_letter]);

  agencyFormData.map((f, index) => {
    if (f.sectionSubtitle === 'Visa') {
      f.fields = agencyVisaSupportLetter;
    }
  });

  // END OF AGENCY VISA SUPPORT LETTER FLOW

  let firestore = {};
  if (isBrowser) {
    firestore = firebase.firestore();
  }

  if (!data) return null;
  if (!isBrowser) return null;
  const pageContent = data.prismicForumRegistrationPage;
  const page = pageContent.data || {};

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const topMenu = data.prismicForumMenuLinks || {};
  const personalPortfolioPage = data.prismicPage || {};

  const saveButton = 'Save';
  const continueButton = 'Payment';

  const localLinkResolver = lang === defaultLanguage ? '/euraceconnect' : `/${lang}/euraceconnect`;
  const isDisabled = false;
  // AGENCY FLOW

  // Agency Form Initial Values
  const agencyFormValuesLocalStorage = localStorage.getItem('agencyFormValues') || null;

  const agencyFormInitialValues = agencyFormValuesLocalStorage
    ? JSON.parse(agencyFormValuesLocalStorage)
    : AGENCY_FORM_CONSTANTS;

  const agencyFormValidationSchema = Yup.object().shape({
    agency_name: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    zip_code: Yup.string().required('Required'),
    vat: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
    how_many_delegates: Yup.string()
      .oneOf(['1', '2', '3', '4', '5'], 'Invalid selection')
      .required('This field is required'),
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    position: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone_number: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
    // if how_many_delegates == 2 first_name_1 and last_name_1 ... are required
    first_name_1: Yup.string().when('how_many_delegates', {
      is: '2',
      then: Yup.string().required('Required'),
    }),
    last_name_1: Yup.string().when('how_many_delegates', {
      is: '2',
      then: Yup.string().required('Required'),
    }),
    position_1: Yup.string().when('how_many_delegates', {
      is: '2',
      then: Yup.string().required('Required'),
    }),
    email_1: Yup.string().when('how_many_delegates', {
      is: '2',
      then: Yup.string().email('Invalid email').required('Required'),
    }),
    phone_number_1: Yup.string().when('how_many_delegates', {
      is: '2',
      then: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
    }),
    // if how_many_delegates == 3 first_name_2 and last_name_2 ... are required
    first_name_2: Yup.string().when('how_many_delegates', {
      is: '3',
      then: Yup.string().required('Required'),
    }),
    last_name_2: Yup.string().when('how_many_delegates', {
      is: '3',
      then: Yup.string().required('Required'),
    }),
    position_2: Yup.string().when('how_many_delegates', {
      is: '3',
      then: Yup.string().required('Required'),
    }),
    email_2: Yup.string().when('how_many_delegates', {
      is: '3',
      then: Yup.string().email('Invalid email').required('Required'),
    }),
    phone_number_2: Yup.string().when('how_many_delegates', {
      is: '3',
      then: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
    }),
    // if how_many_delegates == 4 first_name_3 and last_name_3 ... are required
    first_name_3: Yup.string().when('how_many_delegates', {
      is: '4',
      then: Yup.string().required('Required'),
    }),
    last_name_3: Yup.string().when('how_many_delegates', {
      is: '4',
      then: Yup.string().required('Required'),
    }),
    position_3: Yup.string().when('how_many_delegates', {
      is: '4',
      then: Yup.string().required('Required'),
    }),
    email_3: Yup.string().when('how_many_delegates', {
      is: '4',
      then: Yup.string().email('Invalid email').required('Required'),
    }),
    phone_number_3: Yup.string().when('how_many_delegates', {
      is: '4',
      then: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
    }),
    // if how_many_delegates == 5 first_name_4 and last_name_4 ... are required
    first_name_4: Yup.string().when('how_many_delegates', {
      is: '5',
      then: Yup.string().required('Required'),
    }),
    last_name_4: Yup.string().when('how_many_delegates', {
      is: '5',
      then: Yup.string().required('Required'),
    }),
    position_4: Yup.string().when('how_many_delegates', {
      is: '5',
      then: Yup.string().required('Required'),
    }),
    email_4: Yup.string().when('how_many_delegates', {
      is: '5',
      then: Yup.string().email('Invalid email').required('Required'),
    }),
    phone_number_4: Yup.string().when('how_many_delegates', {
      is: '5',
      then: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
    }),
    agency_v_support_letter: Yup.string()
      .oneOf(['None', '1', '2', '3', '4', '5'], 'Invalid selection')
      .required('This field is required'),
    // required if agency_v_support_letter not empty string and not None
    agency_v_passport_name_0: Yup.string().when('agency_v_support_letter', {
      is: '1',
      then: Yup.string().required('Required'),
    }),
    agency_v_date_of_birth_0: Yup.string().when('agency_v_support_letter', {
      is: '1',
      then: Yup.string().required('Required'),
    }),
    agency_v_passport_number_0: Yup.string().when('agency_v_support_letter', {
      is: '1',
      then: Yup.string().required('Required'),
    }),
    agency_v_passport_expiration_date_0: Yup.string().when('agency_v_support_letter', {
      is: '1',
      then: Yup.string().required('Required'),
    }),
    gala_dinner: Yup.string().required('Required'),
    dietary_restrictions: Yup.array().of(Yup.string()).required('Required'),
    dietary_restrictions_other: Yup.string(),
  });

  // END OF AGENCY FLOW

  const individualFormInitialValues = firstFormValuesLocalStorage
    ? JSON.parse(firstFormValuesLocalStorage)
    : INDIVIDUAL_FORM_CONSTANTS;

  const individualFormValidationSchema = Yup.object().shape({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    certificate_name: Yup.string().required('Required'),
    position: Yup.string().required('Required'),
    organisation: Yup.string().required('Required'),
    educational_program: Yup.string(),
    city: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone_number: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
    vat: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
    visa: Yup.string().required('Required'),
    // required if visa is Yes
    visa_passport_name: Yup.string().when('visa', {
      is: 'Yes',
      then: Yup.string().required('Required'),
    }),
    visa_date_of_birth: Yup.string().when('visa', {
      is: 'Yes',
      then: Yup.string().required('Required'),
    }),
    visa_passport_number: Yup.string().when('visa', {
      is: 'Yes',
      then: Yup.string().required('Required'),
    }),
    visa_passport_expiration_date: Yup.string().when('visa', {
      is: 'Yes',
      then: Yup.string().required('Required'),
    }),
    co_organizer_affiliation: Yup.string().required('Required'),
    evidence_document: Yup.object().shape({
      pdfDocUrl: Yup.string(),
      pdfDocName: Yup.string(),
    }),
    iPEER: Yup.string(),
    main_areas_of_interest: Yup.array().of(Yup.string()).required('Required'),
    main_areas_of_interest_other: Yup.string(),
    gala_dinner: Yup.string().required('Required'),
    dietary_restrictions: Yup.array().of(Yup.string()).required('Required'),
    dietary_restrictions_other: Yup.string(),
    registration_fee: Yup.string().required('Required'),
  });

  /**
   *
   * @param {*} values
   * @param {*} isAgency
   * @param {*} itToClean
   * @returns
   * @description Manage form state in the local storage
   */
  const handleFormValuesPersistence = async (values, isAgency, itToClean) => {
    // if (isAgency) {
    //   // TODO if user selects 4 delegates, fills values, and deselect them, clean the values
    //   //   // values.agency_v_support_letter;
    //   let supportLetterInfoExists = 0;
    //   Object.keys(values).forEach(key => {
    //     if (
    //       key.includes('agency_v_passport_name') ||
    //       key.includes('agency_v_date_of_birth') ||
    //       key.includes('agency_v_passport_number') ||
    //       key.includes('agency_v_passport_expiration_date')
    //     ) {
    //       supportLetterInfoExists++;
    //     }
    //   });
    //   console.log('supportLetterInfoExists', supportLetterInfoExists / 4);
    //   console.log('values.agency_v_support_letter', values.agency_v_support_letter);
    //   console.log(
    //     'supportLetterInfoExists / 4 > ',
    //     supportLetterInfoExists / 4 > values.agency_v_support_letter,
    //   );
    // }
    if (itToClean) {
      const allOptions = document.querySelectorAll('.registration-fee-box');
      allOptions.forEach(option => option.classList.remove('active'));
      localStorage.removeItem('firstFormValues');
      localStorage.removeItem('agencyFormValues');
      setIsToClean(false);
      return;
    }

    if (isAgency) {
      localStorage.setItem('agencyFormValues', JSON.stringify(values));
    } else {
      localStorage.setItem('firstFormValues', JSON.stringify(values));
    }
    !itToClean && setFormValues(values);
  };

  const handleCloseFinalSpinner = () => setShowFinalSpinner(false);
  const handleShowFinalSpinner = () => {
    setShowFinalSpinner(true);
  };

  const handleRedirectToStripe = async values => {
    const backUrl = window.location.href;
    const successUrl = isAgency
      ? `${backUrl}&session_id={CHECKOUT_SESSION_ID}`
      : `${backUrl}?session_id={CHECKOUT_SESSION_ID}`;
    try {
      const result = await axios.post('/api/forum-fee', {
        full_name: values.first_name + ' ' + values.last_name,
        email: values.email || 'no email information',
        price_id: values.registration_fee_id,
        iPEER: values.iPEER,
        how_many_delegates: values.how_many_delegates,
        isAgency: isAgency,
        co_organizer_affiliation: values.co_organizer_affiliation,
        cancelUrl: backUrl, //?payment=cancelled
        successUrl: successUrl,
      });
      window.location = result.data.url;
    } catch (error) {
      alert(`Something went wrong, please try again.
        Your data was saved in a browser.
        But redirect to Stripe was not possible`);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };

  const handleFormSubmitStripe = async ({ values, actions, total_amount, isAgency }) => {
    Object.assign(values, { total_amount });
    if (!isAgency) {
      // Add registration_fee_id to the values
      handleAddPriceId(values);
      if (
        !values.co_organizer_affiliation ||
        !values.registration_fee ||
        !values.registration_fee_id
      ) {
        setAlert_visibility('visible');
        setTimeout(() => {
          setAlert_visibility('invisible');
        }, 2000);
        actions.setSubmitting(false);
      }
    }
    handleShowFinalSpinner();
    try {
      await handleRedirectToStripe(values);
      setTimeout(() => {
        handleCloseFinalSpinner();
      }, 500);
      // }, 4000);
    } catch (error) {
      console.info(error);
    }
  };

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc} isForum>
      <Seo title={page.page_title.text} lang={lang} />
      {!sessionId && (
        <>
          <h1 className="page-title">{isAgency ? agencyFormTitle : formTitle}</h1>
          <div className="text-muted w-100 text-center">
            {isAgency ? 'Not an' : ''} ENAEE Agency?{' '}
            <button
              type="button"
              // button inline styles
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                color: 'blue',
                cursor: 'pointer',
                padding: '0',
              }}
              className=" nav-link_login text-primary cursor-pointer ms-2"
              onClick={() => {
                if (typeof window !== 'undefined') {
                  let url = new URL(window.location.href);
                  let params = new URLSearchParams(url.search);
                  // if isAgency is in the query string, remove it
                  if (params.has('isAgency')) {
                    params.delete('isAgency');
                  } else {
                    // if isAgency is not in the query string, add it
                    params.set('isAgency', 'true');
                  }
                  // Update the URL with the new query parameter
                  url.search = params.toString();
                  // Use pushState to update the URL without reloading the page
                  window.history.pushState({}, '', url);
                } else {
                  console.error('window is not defined');
                }
                setIsAgency(!isAgency);
              }}
            >
              Click here
            </button>{' '}
          </div>
        </>
      )}
      {sessionId && (
        <Container className="container-success for-forum">
          {paymentStatus === 'fulfilled' && (
            <h1 className="page-title">
              <i className="fa fa-check-circle text-success" />
              Success
            </h1>
          )}
          {paymentStatus === 'pending' && (
            <h1 className="page-title">
              <i className="fa fa-check-circle text-warning" />
              Checking...
            </h1>
          )}
          {paymentStatus === 'failed' && (
            <h1 className="page-title">
              <i className="fa fa-times text-danger" />
              Sorry...
            </h1>
          )}
          <div className="">
            <div className="success-message-wrapper">
              {paymentStatus === 'pending' && (
                <div>
                  <div>Fetching your data...</div>
                  <span>If you see this message more than 10 seconds, please refresh the page</span>
                </div>
              )}
              {paymentStatus === 'failed' && <div>It seems something went wrong.</div>}
              {paymentStatus === 'fulfilled' && <div>Thank you!</div>}
              {paymentMessage && <div dangerouslySetInnerHTML={{ __html: paymentMessage }} />}
              <div className="buttons-wrapper">
                <Link className="success-btns" to={localLinkResolver}>
                  <Button variant="outline-success">← Home Page</Button>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      )}
      {!sessionId && (
        <Container className={`personal-portfolio block-two d-block`}>
          {/* FORUM INDIVIDUAL REGISTRATION FORM */}
          {!isAgency && (
            <Formik
              enableReinitialize
              initialValues={individualFormInitialValues}
              isSubmitting
              validationSchema={individualFormValidationSchema}
              onSubmit={(values, actions) => {
                handleFormSubmitStripe({
                  values: values,
                  actions: actions,
                  total_amount: total_amount,
                  isAgency: isAgency,
                });
              }}
            >
              {props => {
                handleFormValuesPersistence(props.values, isAgency, isToClean);
                handleSetTotalAmount(props.values, isAgency);
                handleMainAreasOfInterestOther(props.values);
                handleDietaryRestrictionsOther(props.values);
                return (
                  <Form className="main-form">
                    <ConnectedFocusError />
                    {formData.map(f => LocalForm({ formData: f, props }))}
                    <RequiredAlert alert_visibility={alert_visibility} />
                    <div className="total-amount m-3">
                      <h5>Total amount with applicable discounts (if any): € {total_amount}</h5>
                    </div>
                    <ul>
                      <li>
                        If you wish to proceed with the payment later, click the "SAVE" button to
                        save your data in the browser. You can continue to complete the form by
                        clicking on the registration link on the EUR-ACE Connect Forum website
                        (using the same browser).
                      </li>
                      <li>
                        If you are ready to proceed with the payment and complete your registration,
                        click the "PAYMENT" button.
                      </li>
                    </ul>
                    <p>
                      <strong>Note:</strong> Your registration will not be finalized until the
                      payment is successfully completed.
                    </p>
                    <p className="text-muted">
                      <small>
                        If you wish to delete the inserted data from the form,{' '}
                        <span
                          type="button"
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            color: 'blue',
                            cursor: 'pointer',
                            padding: '0',
                            margin: '0',
                            display: 'inline',
                            width: 'auto',
                          }}
                          onClick={() => {
                            setIsToClean(true);
                            window.scrollTo(0, 0);
                            alert('Form data has been deleted');
                          }}
                          className="text-primary cursor-pointer"
                        >
                          click here.
                        </span>
                      </small>
                    </p>
                    <div className="d-flex flex-column mt-2 ">
                      <hr />
                      <button
                        onClick={() => {
                          handleFormValuesPersistence(props.values, isAgency);
                          alert('Successfully saved');
                        }}
                        type="button"
                        style={{ marginRight: '0' }}
                      >
                        {saveButton}
                      </button>
                      <hr />
                      <button disabled={isDisabled} type="submit" style={{ marginRight: '0' }}>
                        {continueButton}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
          {/* FORUM AGENCY REGISTRATION FORM */}
          {isAgency && (
            <Formik
              enableReinitialize
              initialValues={agencyFormInitialValues}
              isSubmitting
              validationSchema={agencyFormValidationSchema}
              onSubmit={(values, actions) => {
                handleFormSubmitStripe({
                  values: values,
                  actions: actions,
                  total_amount: total_amount,
                  isAgency: isAgency,
                });
              }}
            >
              {props => {
                handleFormValuesPersistence(props.values, isAgency, isToClean);
                handleSetTotalAmount(props.values, isAgency);
                handleDietaryRestrictionsOther(props.values);
                return (
                  <Form className="main-form">
                    <ConnectedFocusError />
                    {agencyFormData.map(f => LocalForm({ formData: f, props }))}
                    <RequiredAlert alert_visibility={alert_visibility} />
                    <div className="total-amount m-3">
                      <h5>Total amount with applicable discounts (if any): € {total_amount}</h5>
                    </div>
                    <ul>
                      <li>
                        If you wish to proceed with the payment later, click the "SAVE" button to
                        save your data in the browser. You can continue to complete the form by
                        clicking on the registration link on the EUR-ACE Connect Forum website
                        (using the same browser).
                      </li>
                      <li>
                        If you are ready to proceed with the payment and complete your registration,
                        click the "PAYMENT" button.
                      </li>
                    </ul>
                    <p>
                      <strong>Note:</strong> Your registration will not be finalized until the
                      payment is successfully completed.
                    </p>
                    <p className="text-muted">
                      <small>
                        If you wish to delete the inserted data from the form,{' '}
                        <span
                          type="button"
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            color: 'blue',
                            cursor: 'pointer',
                            padding: '0',
                            margin: '0',
                            display: 'inline',
                            width: 'auto',
                          }}
                          onClick={() => {
                            setIsToClean(true);
                            window.scrollTo(0, 0);
                            alert('Form data has been deleted');
                            setTimeout(() => {
                              // reload the page
                              window.location.reload();
                            }, 500);
                          }}
                          className="text-primary cursor-pointer"
                        >
                          click here.
                        </span>
                      </small>
                    </p>
                    <div className="d-flex flex-column mt-2 ">
                      <hr />
                      <button
                        onClick={() => {
                          handleFormValuesPersistence(props.values, isAgency);
                          alert('Successfully saved');
                        }}
                        type="button"
                        style={{ marginRight: '0' }}
                      >
                        {saveButton}
                      </button>
                      <hr />
                      <button disabled={isDisabled} type="submit" style={{ marginRight: '0' }}>
                        {continueButton}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Container>
      )}
      <AfterSubmitModal
        showFinalSpinner={showFinalSpinner}
        handleCloseFinalSpinner={handleCloseFinalSpinner}
      />
    </Layout>
  );
}

export const query = graphql`
  query ForumRegistrationPageQuery($id: String, $lang: String) {
    prismicForumRegistrationPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        page_title {
          text
        }
      }
    }
    prismicForumMenuLinks(lang: { eq: $lang }) {
      ...ForumMenuLinksFragment
    }
  }
`;

export default withPrismicPreview(ForumParticipantRegistration, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
