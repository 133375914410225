import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Tilt from 'react-parallax-tilt';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import useLocalStorage from '../Form-components/useLocalStorage';
import './_homepage-slider.scss';

const ControlledTabs = ({ currentLang }) => {
  const rawTabsData = useStaticQuery(
    graphql`
      query {
        allPrismicForumHomepageTab(sort: { fields: data___sequence_number, order: ASC }) {
          nodes {
            lang
            data {
              homepage_tab_slider {
                slider_title {
                  text
                }
                background_image {
                  gatsbyImageData(width: 1440, layout: CONSTRAINED, placeholder: NONE)
                }
                optional_link {
                  url
                }
              }
              homepage_tab_title {
                text
              }
              hot_links {
                link_title
                link {
                  url
                }
              }
            }
          }
        }
      }
    `,
  );

  const tabsData = rawTabsData.allPrismicForumHomepageTab.nodes.filter(doc =>
    doc.lang.includes(currentLang),
  );
  const [key, setKey] = useLocalStorage({
    key: 'homePageTabKey',
    value: 0,
  });
  // const [key, setKey] = useState(0)

  const settings = {
    dots: true,
    fade: true,
    autoplaySpeed: 4000,
    autoplay: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    dotsClass: 'slick-dots slick-thumb',
    easing: 'linear',
    lazyLoad: true,
    pauseOnHover: false,
  };
  useEffect(() => {
    const tabButton = document.querySelector('.nav-item');
    const uiListenerInterval = setInterval(() => {
      const sliderContent = document.querySelectorAll('.homepage-slider__content');
      sliderContent.forEach(content => {
        if (content.lastChild.textContent) {
          content.classList.add('visible');
        }
      });
    }, 1500);
    setTimeout(() => {
      clearInterval(uiListenerInterval);
    }, 10000);
    if (tabButton.lastChild.textContent) {
      tabButton.classList.add('visible');
    }
  }, []);

  return (
    <div id="forum-homepage" className="homepage-slider-wrapper">
      <Tabs
        id="controlled-tab"
        activeKey={key}
        onSelect={k => setKey(k)}
        className="tabs"
        variant="pills"
      >
        {tabsData?.map((tab, key) => {
          return (
            <Tab key={key} eventKey={key} title={tab.data.homepage_tab_title.text}>
              <Slider {...settings}>
                {tab.data.homepage_tab_slider.map((slide, key) => {
                  const image = getImage(slide.background_image.gatsbyImageData);
                  return (
                    <div key={key} className="homepage-slider">
                      <div className="bg"></div>
                      <GatsbyImage image={image} alt={slide.slider_title.text} />
                      <div className="homepage-slider__content">
                        <h2>{slide.slider_title.text}</h2>
                      </div>
                    </div>
                  );
                })}
              </Slider>
              {/* hot-link_cards */}
              <div className="hot-link_wrapper">
                {tab.data.hot_links.map((hotlink, key) => (
                  <Tilt
                    key={key}
                    tiltMaxAngleX={6}
                    tiltMaxAngleY={6}
                    perspective={900}
                    scale={1.05}
                    transitionSpeed={2000}
                    gyroscope={true}
                  >
                    <Link className="hot-link_card" to={hotlink.link.url}>
                      {hotlink.link_title}
                    </Link>
                  </Tilt>
                ))}
              </div>
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default ControlledTabs;

// https://react-slick.neostack.com/docs/example/custom-paging
