// SliceZone.js file

import * as React from 'react';

import { FullWidthImage } from './slices/FullWidthImage';
import { ImageGallery } from './slices/ImageGallery';
import { ImageHighlight } from './slices/ImageHighlight';
import { Quote } from './slices/Quote';
import { Text } from './slices/Text';
import { Table } from './slices/Table';
import { RTable } from './slices/RTable';

export const NewsSliceZone = ({ newsSliceZone }) => {
  const newsSliceComponents = {
    full_width_image: FullWidthImage,
    image_gallery: ImageGallery,
    image_highlight: ImageHighlight,
    quote: Quote,
    text: Text,
    table: Table,
    RTable: RTable,
  };

  const newsSliceZoneContent = newsSliceZone.map((slice, index) => {
    const NewsSliceComponent = newsSliceComponents[slice.slice_type];
    if (NewsSliceComponent) {
      return <NewsSliceComponent slice={slice} key={`slice-${index}`} />;
    }
    return null;
  });

  return <main className="container">{newsSliceZoneContent}</main>;
};
