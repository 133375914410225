import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout } from '../components/Layout';
import { linkResolver } from '../utils/LinkResolver';
import { Seo } from '../components/Seo';
import ForumTabs from '../components/Forum-Homepage-components copy/ForumTabs';

function HomeTemplate({ data }) {
  if (!data) return null;

  const pageContent = data.prismicForumHomepage;
  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const topMenu = data.prismicForumMenuLinks || {};
  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc} isForum>
      <Seo title="Forum Homepage" lang={lang} />
      <ForumTabs
        currentLang={lang}
        // title={RichText.asText(doc.homepage.raw)}
      />
    </Layout>
  );
}

export const query = graphql`
  query forumHomepageQuery($lang: String) {
    prismicForumHomepage(lang: { eq: $lang }) {
      lang
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
    }
    prismicForumMenuLinks(lang: { eq: $lang }) {
      ...ForumMenuLinksFragment
    }
  }
`;

export default HomeTemplate;
