import { priceAgency } from '../constants/constants.js';

export const AGENCY_FORM_CONSTANTS = {
  agency_name: '',
  country: '',
  city: '',
  address: '',
  zip_code: '',
  vat: '',
  how_many_delegates: '',
  first_name: '',
  last_name: '',
  position: '',
  email: '',
  phone_number: '',
  agency_v_support_letter: '',
  gala_dinner: '',
  dietary_restrictions: '',
  dietary_restrictions_other: '-',
  registration_fee: 'Agency Registration Fee - € 500',
  registration_fee_id: priceAgency,
};
export const INDIVIDUAL_FORM_CONSTANTS = {
  first_name: '',
  last_name: '',
  certificate_name: '',
  position: '',
  organisation: '',
  educational_program: '',
  city: '',
  country: '',
  email: '',
  phone_number: '',
  vat: '',
  visa: '',
  co_organizer_affiliation: '',
  iPEER: '',
  evidence_document: { pdfDocUrl: '', pdfDocName: '' },
  pdf_name: '',
  gala_dinner: '',
  dietary_restrictions: '',
  dietary_restrictions_other: '-',
  registration_fee: '',
  registration_fee_id: '', // stripe price id
  main_areas_of_interest: '',
  main_areas_of_interest_other: '-',
};
